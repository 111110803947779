/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";
@import "ngx-toastr/toastr";
@import "assets/custom-style.css";
@import "assets/feathericon.min.css";
@import "assets/admin-style.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap'); */

:root {
  --bodyfont: "Poppins", sans-serif;
  --yellowcolor: #ffa124;
  --bluecolor: #1797c5;
  --navyblue: #1c366a;
  --transitn: 0.4s all ease-in-out;
}

body {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #1b263a;
  font-family: var(--bodyfont);
}

body.modal-open {
  counter-reset: numbering;
}

.wrapper.mm-page {
  /* z-index: 0; */
  position: initial;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p:last-child {
  margin-bottom: 0px;
}

span,
mark {
  display: inline-block;
}

a {
  transition: all 0.5s ease-in-out;
  display: inline-block;
  color: #233f88;
}

a:hover {
  color: #ffa124;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

section {
  padding: 90px 0;
  position: relative;
}

.accordion-button:focus {
  box-shadow: inherit;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
} */

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.container {
  max-width: 1170px;
  padding: 0px 15px;
  margin: 0 auto;
  position: relative;
}

input[type="submit"],
button,
.button {
  transition: 0.4s all ease-in-out;
}

.banner_slider .owl-dots {
  position: absolute;
  bottom: 250px;
  right: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  text-align: right !important;
  counter-reset: my-sec-counter;
  margin-top: 0;
  padding: 0 15px;
}

.home_page .banner_slider .owl-item::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.26);
  z-index: 1;
}

.banner_slider .owl-dot span {
  position: relative;
  margin: 0 7px !important;
  background: transparent !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 0 !important;
  color: #ccc;
  font-size: 21px;
  line-height: 1;
  border-bottom: 3px solid transparent;
}

.banner_slider .owl-dot:last-child span {
  margin-right: 0 !important;
}

.banner_slider .owl-dot span:before {
  position: absolute;
  counter-increment: my-sec-counter;
  content: "0" counter(my-sec-counter);
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
}

.banner_slider .active.owl-dot span:before {
  font-size: 25px;
  font-weight: 600;
  color: #ffffff;
}

.banner_slider .active.owl-dot span {
  border-color: #ffd000;
}

.owl-carousel .owl-item img {
  width: auto;
}

.mental_main .owl-carousel .owl-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: 0;
}

.mental_main .owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  margin: 0;
  padding: 4px 7px;
  background: #ffd000;
  display: inline-flex;
  cursor: pointer;
  border-radius: 0;
  align-items: center;
  justify-content: center;
}

.mental_main .owl-prev {
  margin-left: -30px !important;
}

.mental_main .owl-next {
  margin-right: -30px !important;
}

body.offcanvas-active {
  overflow: hidden;
}

.offcanvas-header {
  display: none;
}

.screen-darken {
  height: 100%;
  width: 0%;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity 0.2s linear, visibility 0.2s, width 2s ease-in;
}

.screen-darken.active {
  z-index: 10;
  transition: opacity 0.3s ease, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}

.navbar-toggler {
  display: none;
}

.btm_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.breadcrumb {
  padding: 0;
  background: transparent;
}

.bradcmp .breadcrumb li a,
.bradcmp .breadcrumb li {
  font-size: 16px;
}

.fluidTitle .sec_title > span {
  top: -40px;
  transform: translate(-50%, 0);
}

.alert-danger {
  padding: 0;
  background: transparent;
  border: 0;
  font-size: 14px;
  color: red;
  margin-top: 4px;
}

.yellow_btn {
  background: #ffce00;
  color: #010101;
  text-transform: uppercase;
  padding: 13px 20px;
  line-height: 1;
  border: 0;
  border-radius: 4px;
}

.yellow_btn:hover {
  background: #3b4652;
  color: #fff;
}

.dash_title {
  font-size: 25px;
  font-weight: 300;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 20px;
}

.mat-horizontal-stepper-header-container {
  display: none !important;
}

.btn-close:focus {
  box-shadow: inherit;
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

.question_part .mdc-form-field > label {
  display: block;
  padding: 0;
  margin-left: 4px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  /* margin-top: 5px; */
}

.question_part .mdc-form-field {
  display: flex;
}

.question_part .mdc-radio__outer-circle {
  border-width: 5px;
}

.question_part
  .mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: #fc0 !important;
}

.question_part
  .mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: #fc0 !important;
  background: #fc0 !important;
}

.question_part
  .mdc-radio__native-control:checked
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  transform: scale(0.6);
}

.question_part
  .mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: #fc0 !important;
}

.question_part .mdc-radio__background {
  width: 34px;
  height: 34px;
}

.question_part .mdc-radio {
  width: auto;
  height: auto;
  padding: 0 !important;
}

.assmnt_articl p {
  font-size: 12px;
  color: #333333;
  font-weight: 500;
}

.test_quiz .modal-content {
  height: 100%;
  border-radius: 0;
}

.test_quiz .modal-dialog {
  height: 860px;
}

body {
  counter-reset: my-sec-counter;
}

.assemnt_main .owl-carousel.owl-drag .owl-item {
  padding: 30px 0;
}

.assemnt_main .owl-theme .owl-nav .owl-prev {
  background: #f8c34f url("assets/images/right_arrow.png") no-repeat center
    center / 11px;
  margin: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  transform: rotate(180deg);
  margin-right: 12px;
}

.assemnt_main .owl-theme .owl-nav .owl-next {
  background: #f8c34f url("assets/images/right_arrow.png") no-repeat center
    center / 11px;
  margin: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  /* transform: rotate(180deg); */
}

.assemnt_main .owl-theme .owl-nav {
  margin-top: 20px;
}

/* work sec css  */
.work_list h5 {
  font-size: 16px;
  line-height: 1;
  color: #000000;
  text-transform: uppercase;
}

.work_list p {
  color: #777777;
}

.work_list li {
  padding-bottom: 20px;
  position: relative;
  padding-left: 28px;
}

.work_list li:last-child {
  padding-bottom: 0;
}

.work_list li::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 2px;
  background: #ffcc00;
  left: 0;
  top: 0;
}

.work_list li:last-child:before {
  display: none;
}

.work_list li::after {
  position: absolute;
  content: "";
  background: #fff url(assets/images/tick.png) no-repeat 0 0 / 14px;
  left: -5px;
  top: 0;
  width: 14px;
  height: 14px;
}

.work_list {
  margin-bottom: 0;
}

.work_right {
  padding-left: 38px;
}

.file-icon img {
  margin-right: 10px;
}

.input-group-append {
  height: 100%;
}

.sidebar-menu .dropdown-menu.show {
  display: block;
  position: relative !important;
  transform: inherit !important;
  width: 100%;
}

.assessment_price {
  position: absolute;
  right: 3px;
  top: 5px;
  background: #233f88;
  color: #fff;
  padding: 3px 6px;
  font-size: 13px;
  border: 0;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.assessment_paid {
  position: absolute;
  right: 3px;
  top: 5px;
  background: #5cb85c;
  color: #fff;
  padding: 3px 6px;
  font-size: 13px;
  border: 0;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.moddinr.rating span {
  font-size: 50px;
  line-height: 1;
  color: #ffce00;
}

mark.val {
  position: relative;
  bottom: 16px;
  right: 4px;
  background: transparent;
}

.progrs tspan {
  font-size: 0;
}

.fc-col-header {
  width: auto !important;
}

.fc .fc-daygrid-body,
.fc .fc-scrollgrid,
.fc .fc-scrollgrid table {
  width: 100% !important;
  height: 100% !important;
}

.fc .fc-scroller-harness-liquid {
  height: 100% !important;
}

.fc .fc-timegrid-event-harness {
  background-color: #ffce00;
  color: #fff;
}

.fc .fc-timegrid-event-harness-inset {
  left: 0 !important;
  box-shadow: none !important;
}
.fc .fc-timegrid-event {
  box-shadow: none !important;
}

.fc .fc-daygrid-day-frame {
  min-height: 50px;
}

.fc .fc-daygrid-event-harness {
  background-color: #ffce00;
  color: #fff;
  padding: 1px 0;
  margin: 5px;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background: rgba(0, 0, 0, 0);
}

.fc-event,
.fc-event-dot {
  background-color: rgba(0, 0, 0, 0);
}

.fc .fc-content {
  padding: 0 5px;
  overflow-x: hidden;

  text-overflow: ellipsis;
}
.fc .fc-content span {
  color: #000;
  white-space: nowrap;
}

.inputbox {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}

.select_filter {
  width: 30%;
}

.inputbox .input-group {
  width: 50%;
  padding: 0 5px;
}

.filter_track .select-period .form-select {
  width: 100% !important;
  margin: 0 !important;
}

.inputbox .btn.calendar {
  padding: 0 15px;
}

.filter_track .form-control {
  height: 100%;
}

/* responsive  */
@media (min-width: 1366px) {
  .banner_slider .owl-carousel.owl-drag .owl-item {
    height: auto !important;
  }
}

@media (min-width: 1200px) {
  .test_quiz .modal-xl {
    max-width: 1170px;
  }
}

@media (max-width: 1400px) {
  .test_quiz .modal-content {
    height: auto;
  }

  .test_quiz .modal-dialog {
    height: auto;
  }
}

@media (max-width: 1199px) {
  .wrapper .mental_main .owl-carousel .owl-nav {
    width: 2%;
    position: static;
    margin: 20px auto auto;
  }

  .wrapper .mental_main .owl-theme .owl-nav [class*="owl-"] {
    border-radius: 5px;
    padding: 4px 12px;
    margin-right: 10px;
  }

  .wrapper .mental_main .owl-carousel .owl-nav img {
    width: 12px;
  }

  .fluidTitle .sec_title h2 {
    font-size: 35px;
  }

  .sec_title > span {
    font-size: 130px;
  }

  .daynight_list li {
    font-size: 13px;
  }

  .banner_slider .owl-dots {
    bottom: 46%;
  }

  .orange_btn {
    font-size: 15px;
    padding: 17px 14px;
  }

  .test_quiz .modal-content {
    height: auto;
  }

  .work_right {
    padding-left: 0;
  }
}

@media (max-width: 1024px) {
  .test_body .question_part.tahnks_msg h4 br {
    display: none;
  }
}

@media (max-width: 991px) {
  .outer_banner .banner_cont h2 {
    font-size: 40px;
  }

  .outer_banner .banner_cont h2 span {
    font-size: 35px;
  }

  .banner_slider .owl-dots {
    bottom: 40%;
  }

  .sec_title > span {
    font-size: 160px;
  }

  .sec_title h2 {
    font-size: 30px;
  }

  .inner_mentak .owl-theme .owl-nav {
    margin-top: 20px;
  }

  section {
    padding: 60px 0;
  }

  .therapy_sec {
    padding-bottom: 120px;
  }

  .choose_mid_main {
    margin: 10px 0 !important;
  }

  .sec_title.sec_title2 {
    padding-top: 22px;
  }

  .feeling_iner .sec_title p {
    margin-bottom: 7px;
  }

  .site-main.inner_pg_site {
    padding-top: 76px;
  }

  .fluidTitle .sec_title > span {
    top: 0;
  }

  .fluidTitle .sec_title h2 {
    font-size: 28px;
  }

  .steps ul li span {
    margin-left: 10px;
  }

  .overlay::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(34, 34, 34, 0.767);
    z-index: 9998;
  }

  .banner_slider .owl-dots {
    position: static;
    text-align: center !important;
    background: #000;
    margin: 0 !important;
    padding: 10px;
  }

  .wrapper .mental_main .owl-carousel .owl-nav {
    width: 100%;
  }

  .wrapper .mental_main .owl-theme .owl-nav [class*="owl-"] {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .modal-dialog {
    max-width: calc(100% - 20px);
    margin: 0 auto;
  }

  .work_right {
    padding-left: 0;
    margin-top: 30px;
  }
  .home_page .owl-carousel.owl-drag .owl-item {
    height: 100% !important;
  }
}

@media (max-width: 767px) {
  .banner_slider .owl-dots {
    text-align: center !important;
    position: static;
    background: #000;
    margin: 0 !important;
    padding: 5px;
    border-top: 1px solid #ffd000;
  }

  .orange_btn {
    padding: 14px 14px;
  }

  .fancybox img {
    width: 60px;
  }

  .fluidTitle {
    display: block;
    margin-bottom: 0;
  }

  .fluidTitle .sec_title {
    width: 100%;
    text-align: center;
    padding-right: 0;
  }

  .fluidTitle .paragrph {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    padding-left: 0;
  }

  .inner_care {
    text-align: center;
  }

  .sec_title > span {
    font-size: 130px;
    line-height: 90px;
  }

  .sec_title {
    margin-bottom: 50px;
  }

  .normaltitle {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .outer_bnr h2 {
    font-size: 27px;
  }

  .fluidTitle .sec_title h2 {
    font-size: 25px;
  }

  .sec_title.sec_title2 h2 {
    margin-bottom: 0;
  }

  .sec_title.sec_title2::before {
    bottom: 0;
  }

  .question_part .mdc-form-field > label {
    font-size: 14px;
  }
  .home_page .owl-carousel.owl-drag .owl-item {
    height: auto !important;
  }
  .banner_slider .owl-stage {
    background: #000;
  }
}

@media (max-width: 479px) {
  .sec_title h2 {
    font-size: 25px;
  }

  .normaltitle {
    font-size: 22px;
  }

  .question_part
    .mdc-radio__native-control:checked
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    transform: scale(0.4);
  }
  .site-main.inner_pg_site {
    padding-top: 135px;
  }
}
/* calender close icon fix */
.fc .fc-popover-close {
  cursor: pointer;
  font-size: 1.1em;
  opacity: 1;
  height: auto;
}
